<template>
  <div class="four-o-four">
    <div></div>
      <section class="four-o-four-section">
        <a @click="goToLogin" class="btn">
          <AliceLogoMark :width="'170px'" :height="'170px'" />
        </a>
        <h1 class="title">404 Error</h1>
        <h2 class="subtitle">The requested URL was not found<span class="period">.</span></h2>
      </section>
      <footer class="footer-bobble-graph">
        <img src="../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
      </footer>
  </div>
</template>

<script>
import AliceLogoMark from '../components/shared/AliceLogoMark.vue';
import router from '../router'

export default {
    name: 'four-o-four',
    methods: {
        goToLogin() {
            router.push('/login');
        }
    },
    components: { AliceLogoMark }
}      

</script>

<style src="@/assets/styles/views/404.css"></style>
